.link {
  margin-right: 10px !important;
}

.promptsList {
  background-color: white;
  box-shadow: 7px 12px 24px #cbced1, -12px -12px 24px #ffffff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.promptsList th {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-transform: none;
}

.promptsList td {
  border-bottom: 0;
}

.promptsList tr {
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}

td.promptName {
  display: flex;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.tag {
  background-color: white !important;
  border-radius: 4px !important;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-right: 10px;
  padding: 3px 6px;
  margin-bottom: 10px;
}
