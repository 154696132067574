.headerBanner h2 {
  font-weight: 500;
}

.infinity {
  margin-bottom: -4.5px !important;
}

.limit {
  min-width: 55px;
  display: inline-block;
}

.slash {
  margin: 0 3px;
}

.statistics {
  background-color: white;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff;
  padding: 40px 10px 10px 10px;
  /* width: 400px; */
  border-radius: 16px;
}

.statistics h2 {
  font-weight: 400;
  margin-top: 0;
}

.statistics ul {
  margin-top: 30px;
  margin-bottom: 35px;
}

.statistics ul li {
  align-items: center;
  display: flex;
}

.statistics ul .label {
  color: var(--chakra-colors-primary-500);
  font-size: 1.2rem;
  font-weight: 500;
}

.statistics ul .value {
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 10px;
  letter-spacing: 2px;
}
