.information {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  display: flex;
  margin-bottom: 50px;
  padding: 15px;
}

.informationIcon {
  color: var(--chakra-colors-primary-500) !important;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 15px;
}
