.modalContent {
  background-color: var(--chakra-colors-containerBackground-500) !important;
  max-width: 1200px !important;
}

.title {
  background-color: white;
  border-radius: 16px;
  color: var(--chakra-colors-primary-500);
  font-size: 2rem !important;
  margin-bottom: 24px;
  text-align: center;
}
