.PromptOptimizeRunButton_subscribeLink__oLOr6 a {
  color: white !important;
}

.CopyToClipboardIcon_icon___1DMd {
  color: var(--chakra-colors-primary-500) !important;
  cursor: pointer;
}

.PromptOptimizeModalContent_content__r6ZZQ {
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 20px #d9d9d9, -10px -10px 20px #ffffff;
  padding: 15px;
}

.PromptOptimizeModalContent_subsection__9JuX6 {
  margin-bottom: 35px;
}

.PromptOptimizeModalContent_subtitle__UOyyf {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  margin-bottom: 15px;
}

.PromptOptimizeModalContent_text__Vmn9l {
  width: 100%;
}

.InformationBox_information__3fIaC {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  display: flex;
  margin-bottom: 50px;
  padding: 15px;
}

.InformationBox_informationIcon__cVAfr {
  color: var(--chakra-colors-primary-500) !important;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 15px;
}

h2.LastItemsList_title__fXIRd {
  font-weight: 400;
  margin-top: 0;
}

.LastItemsList_list__GafqO {
  font-size: 1.1rem;
  line-height: 2rem;
  margin-left: 20px;
  margin-top: 20px;
}

.LastItemsList_list__GafqO li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LastItemsList_container__RQ7sV {
  background-color: #ffffff;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff;
}

h2.LastTagsList_title__sVGdP {
  font-weight: 400;
  margin-top: 0;
}

.LastTagsList_list__eh9Et {
  font-size: 1.1rem;
  line-height: 2rem;
  margin-left: 20px;
  margin-top: 20px;
}

.LastTagsList_container__sa_K0 {
  background-color: #ffffff;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff;
}

.HomeUserStatistics_headerBanner__X9KFm h2 {
  font-weight: 500;
}

.HomeUserStatistics_infinity__8JLLg {
  margin-bottom: -4.5px !important;
}

.HomeUserStatistics_limit__rRSga {
  min-width: 55px;
  display: inline-block;
}

.HomeUserStatistics_slash__x_oVA {
  margin: 0 3px;
}

.HomeUserStatistics_statistics__ny6G9 {
  background-color: white;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff;
  padding: 40px 10px 10px 10px;
  /* width: 400px; */
  border-radius: 16px;
}

.HomeUserStatistics_statistics__ny6G9 h2 {
  font-weight: 400;
  margin-top: 0;
}

.HomeUserStatistics_statistics__ny6G9 ul {
  margin-top: 30px;
  margin-bottom: 35px;
}

.HomeUserStatistics_statistics__ny6G9 ul li {
  align-items: center;
  display: flex;
}

.HomeUserStatistics_statistics__ny6G9 ul .HomeUserStatistics_label__3CkIn {
  color: var(--chakra-colors-primary-500);
  font-size: 1.2rem;
  font-weight: 500;
}

.HomeUserStatistics_statistics__ny6G9 ul .HomeUserStatistics_value__4ekkU {
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 10px;
  letter-spacing: 2px;
}

.ErrorContainer_button__1sC6Q {
  margin-top: 30px !important;
}

.Subscription_card__e0WVu {
  margin-top: 0 !important;
}

.Subscription_subscribeButton__KgkxL {
  margin-bottom: 20px !important;
  margin-top: 40px !important;
}

.Footer_footer__cnBof {
  margin-top: 40px !important;
}

.Footer_footer__cnBof a {
  color: black;
}

.ProductCard_cardBody__IBbRp div {
  padding: 20px !important;
}

.ProductCard_cardBody__IBbRp div:first-of-type {
  margin-bottom: 20px;
  padding: 0px !important;
}

.ProductCard_cardBody__IBbRp hr {
  border-color: #eeeeee;
  margin-bottom: 8px;
  margin-top: 8px;
}

.ProductCard_cardBody__IBbRp {
  text-align: center;
  padding-top: 0px !important;
}

.ProductCard_cardFooter__Ku5D5 {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
}

.AppBar_rightBlock__TRXAG img {
  display: none;
}

.AppBar_rightBlock__TRXAG {
  width: 45%;
  justify-content: end !important;
}

.AppBar_questionIcon__RLFPd div {
  font-size: 2.4rem;
  font-weight: 500;
}

.AppBar_rightBlock__TRXAG > div > div > button > div {
  background-color: white;
  background-image: url('/img/avatar.svg') !important;
}

.AppBar_menuList__jQ_gy button a {
  width: 100%;
}

.AppBar_emailIcon__zrLX0,
.AppBar_questionIcon__RLFPd {
  background-color: var(--chakra-colors-primary-500) !important;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.AppBar_emailIcon__zrLX0 svg {
  margin-top: 12px;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_061548';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/aec774cbe1963439-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_061548';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/634216363f5c73c1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_061548';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d83fe381bb17eb77-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_061548';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/88325a2c1fede2f4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_061548';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/e1c529c04de64b40-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_061548';src: local("Arial");ascent-override: 84.95%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.95%
}.__className_061548 {font-family: '__Montserrat_061548', '__Montserrat_Fallback_061548';font-style: normal
}

.PromptOptimizeModal_modalContent__RJv00 {
  background-color: var(--chakra-colors-containerBackground-500) !important;
  max-width: 1200px !important;
}

.PromptOptimizeModal_title__oNzC7 {
  background-color: white;
  border-radius: 16px;
  color: var(--chakra-colors-primary-500);
  font-size: 2rem !important;
  margin-bottom: 24px;
  text-align: center;
}

.AddPromptForm_promptNameInput__rbbZf,
.AddPromptForm_promptTags__FZ7YC {
  margin-bottom: 30px;
}

.AddPromptForm_promptFormLabel__Aa3ki,
.AddPromptForm_promptNameLabel__vvZPe,
.AddPromptForm_promptTagsLabel__IrayC {
  color: var(--chakra-colors-primary-500);
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.AddPromptForm_promptTabPanel__YyZnI {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.AddTagInput_addButton__pDEGp:hover {
  background-color: var(--chakra-colors-primary-500) !important;
}

.PromptsList_link__m5SUu {
  margin-right: 10px !important;
}

.PromptsList_promptsList__K2DLu {
  background-color: white;
  box-shadow: 7px 12px 24px #cbced1, -12px -12px 24px #ffffff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.PromptsList_promptsList__K2DLu th {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-transform: none;
}

.PromptsList_promptsList__K2DLu td {
  border-bottom: 0;
}

.PromptsList_promptsList__K2DLu tr {
  border-bottom: var(--chakra-borders-1px);
  border-color: var(--chakra-colors-gray-100);
}

td.PromptsList_promptName__hJEEm {
  display: flex;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.PromptsList_tag__sjVP3 {
  background-color: white !important;
  border-radius: 4px !important;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-right: 10px;
  padding: 3px 6px;
  margin-bottom: 10px;
}

.Tag_editButton__cl625:hover,
.Tag_editIcon__3UsBg:hover {
  background-color: var(--chakra-colors-primary-500) !important;
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  scroll-behavior: smooth;
}
body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}
h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
pre {
  background-color: rgba(55, 65, 81, 1);
  border-radius: 0.25rem;
  color: rgba(229, 231, 235, 1);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  overflow: scroll;
  padding: 0.5rem 0.75rem;
}
.shadow {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.rounded {
  border-radius: 1.5rem;
}
.wrapper {
  width: 100%;
}

/*
 * Ensure error modal not displayed
 */
nextjs-portal {
  display: none;
}

