.promptNameInput,
.promptTags {
  margin-bottom: 30px;
}

.promptFormLabel,
.promptNameLabel,
.promptTagsLabel {
  color: var(--chakra-colors-primary-500);
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.promptTabPanel {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
