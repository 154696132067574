.cardBody div {
  padding: 20px !important;
}

.cardBody div:first-of-type {
  margin-bottom: 20px;
  padding: 0px !important;
}

.cardBody hr {
  border-color: #eeeeee;
  margin-bottom: 8px;
  margin-top: 8px;
}

.cardBody {
  text-align: center;
  padding-top: 0px !important;
}

.cardFooter {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
}
