.rightBlock img {
  display: none;
}

.rightBlock {
  width: 45%;
  justify-content: end !important;
}

.questionIcon div {
  font-size: 2.4rem;
  font-weight: 500;
}

.rightBlock > div > div > button > div {
  background-color: white;
  background-image: url('/img/avatar.svg') !important;
}

.menuList button a {
  width: 100%;
}

.emailIcon,
.questionIcon {
  background-color: var(--chakra-colors-primary-500) !important;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.emailIcon svg {
  margin-top: 12px;
}
