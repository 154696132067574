.content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 20px #d9d9d9, -10px -10px 20px #ffffff;
  padding: 15px;
}

.subsection {
  margin-bottom: 35px;
}

.subtitle {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  margin-bottom: 15px;
}

.text {
  width: 100%;
}
