h2.title {
  font-weight: 400;
  margin-top: 0;
}

.list {
  font-size: 1.1rem;
  line-height: 2rem;
  margin-left: 20px;
  margin-top: 20px;
}

.container {
  background-color: #ffffff;
  box-shadow: 6px 6px 12px #cbced1, -6px -6px 12px #ffffff;
}
