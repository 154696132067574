html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
}
body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}
h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
pre {
  background-color: rgba(55, 65, 81, 1);
  border-radius: 0.25rem;
  color: rgba(229, 231, 235, 1);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  overflow: scroll;
  padding: 0.5rem 0.75rem;
}
.shadow {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.rounded {
  border-radius: 1.5rem;
}
.wrapper {
  width: 100%;
}

/*
 * Ensure error modal not displayed
 */
nextjs-portal {
  display: none;
}
